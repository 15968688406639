@use '@/styles/utils' as *;

.container {
    align-items: center;
    background: $color-base;
    container-type: inline-size;
    display: flex;
    justify-content: space-between;
    margin-inline: auto;
    max-width: 1440px;
    padding: 1.5rem 4.8rem 0.5rem;

    @media (width <= 768px) {
        padding: 1.5rem 2.4rem 0.5rem;
    }
}

.actionsWrapper {
    align-items: center;
    display: inline-flex;
    gap: 2rem;
}

.subscribeText,
.login {
    color: $color-accent-blue;
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    pointer-events: none;
    text-transform: uppercase;
}

.login {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-family: $fonts-saira;
    gap: 0.5rem;
    justify-content: center;
    pointer-events: all;

    svg {
        fill: $color-accent-blue;
    }

    &:hover {
        opacity: 0.8;
    }

    &.isLoggedIn:first-of-type {
        pointer-events: none;

        &:hover {
            opacity: 1;
        }
    }
}

.submit {
    border-image: url('/assets/images/tape-effect--blue.svg');
    border-image-outset: 8px 8px 8px 8px;
    border-image-repeat: stretch stretch;
    border-image-slice: 0 0 0 0 fill;
    border-image-width: 10px 10px 10px 10px;
    color: $color-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: normal;
    padding: 2rem 5rem;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    z-index: 2;
}

.burgerMenu {
    border: 1px solid $color-accent-red;
    border-radius: 8px;
    max-height: 5rem;
    padding: 0;
    width: 5rem;
}

.burgerIcon {
    path {
        stroke: $color-accent-red;
    }
}

@container (width < 768px) {
    .desktopElement {
        display: none;
    }
}

@container (width >= 768px) {
    .burgerMenu {
        display: none;
    }
}

.modalOverlay {
    background-image: url('/assets/images/footer-bg.jpg');
    background-position: center;
    background-repeat: repeat-y;
}

.modalClose {
    border: 1px solid $color-accent-red;
    border-radius: 8px;
    cursor: pointer;
    height: 5rem;
    padding: 0;
    right: 2.4rem;
    top: 2.1rem;
    width: 5rem;
    z-index: 15;
}

.modalWrapper {
    height: 100%;
    max-height: unset;
    max-width: 1440px;
    overflow: hidden;
    padding: 1.5rem 3.2rem 1rem;
    position: relative;
    width: 100%;

    @media (width <= 768px) {
        padding: 1.5rem 2.4rem 0.5rem;
    }
}

.tearEffect {
    --tear-height: 25px;

    bottom: calc((var(--tear-height) - 1px) * -1);
    height: var(--tear-height);
    inline-size: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;

    &.hidden {
        display: none;
    }

    path {
        fill: $color-base;
    }
}

.gameLogo {
    height: 56px;
    max-width: 100%;
    width: 200px;
}
