@use '@/styles/utils' as *;

.splitMedia {
    position: relative;

    &::before {
        background-color: $color-base;
        content: '';
        inset: 0;
        mask-image: url('/assets/images/top-tear.svg');
        mask-position: top;
        mask-repeat: no-repeat;
        mask-size: 100% auto;
        position: absolute;
        z-index: 2;
    }
}

.layout {
    align-items: center;
    column-gap: 3.2rem;
    display: grid;
    grid-template:
        'mainImage textBox' auto
        / 1fr 1fr;
    justify-content: flex-end;
    margin-block: 10rem 4rem;
    padding-inline: 5.8rem;
    position: relative;
    z-index: 3;

    @media (width <= 1200px) {
        grid-template:
            'mainImage' auto
            'textBox' auto
            / 1fr;
        margin-block: 6rem 4rem;
        padding-block: 3.2rem;
        padding-inline: 1.5rem;
        row-gap: 3.2rem;

        .character {
            max-height: min(600px, 50vh);
            object-fit: contain;
        }
    }
}

.character {
    height: auto;
    margin-block-start: -2rem;
    object-fit: contain;
    width: 100%;
    z-index: 0;
}

.textBox {
    @include break-word;

    align-self: center;
    line-height: 1.45;
    padding-block: 5rem;

    @media (width <= $container-width-mid) {
        margin-bottom: 5rem;
        margin-top: -10rem;
    }
}

.secondaryTitle {
    margin-block: 2.5rem;

    @media (width <= $container-width-min) {
        font-size: 1.8rem;
    }
}
