@use '@/styles/utils' as *;

.wrapper {
    --buffer: 120px;

    margin-block-start: (calc(var(--buffer) * -1));
    padding: 10rem;
    position: relative;
    z-index: 3;

    @media (width <= $container-width-min) {
        padding: 10rem 2rem;
    }
}

.paperBoxContainer {
    margin-block: 5rem;

    p {
        margin-block-start: 3rem;
        text-align: center;
    }

    .paperBox {
        z-index: 2;
    }

    .tapeBox {
        margin-inline: auto;
        max-width: 100%;
        width: max-content;

        h3 {
            padding: 4rem 6rem;
        }
    }
}

.bgImage {
    clip-path: polygon(0 10px, 100% calc(var(--buffer) - 10px), 100% 100%, 0 100%);
    object-fit: cover;
}

.tearEffect {
    fill: $color-base;
    height: var(--buffer);
    left: 0;
    position: absolute;
    top: 0;
    translate: 0 0%;
    width: 100%;
}

.platformList {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
    list-style: none;
    margin: 4rem 3rem;
    padding: 0;

    @media (width <= $container-width-min) {
        grid-template-columns: 1fr;
    }

    @media (width <= 768px) {
        margin-inline: 0;
    }

    a {
        text-decoration: none;
    }
}

.platform {
    @include button;

    align-items: center;
    border-image: url('/assets/images/tape-effect--blue.svg');
    border-image-outset: 8px 8px 8px 8px;
    border-image-repeat: stretch stretch;
    border-image-slice: 0 0 0 0 fill;
    border-image-width: 10px 10px 10px 10px;
    color: $color-base;
    display: flex;
    font-size: 3rem;
    gap: 1.5rem;
    height: 100%;
    justify-content: center;
    padding: 3rem 4rem;
    width: 100%;

    @media (width <= 768px) {
        font-size: 2rem;
        gap: 1rem;
        padding-inline: 1rem;
    }

    svg,
    img {
        height: 1.2em;
        width: 1.2em;
    }

    span {
        text-wrap: nowrap;
    }
}
