@use '@/styles/utils.scss' as *;

.button {
    --default-1: #{$color-main-rgb};
    --default-2: #{$color-base-rgb};

    align-items: center;
    background-color: rgb(var(--background-color, var(--default-1)) / var(--background-alpha, 12%));
    border: 1px solid rgb(var(--border-color, var(--default-1)) / var(--border-alpha, 100%));
    border-radius: 0.29em;
    color: rgb(var(--color, var(--default-1)) / 100%);
    cursor: pointer;
    display: inline-flex;
    font-size: 1.4rem;
    font-weight: 600;
    gap: 0.8ch;
    justify-content: center;
    line-height: 1.712;
    min-width: 4rem;
    padding-block: 0.645em;
    padding-inline: 1em;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        background-color: rgb(
            var(--background-color, var(--default-1)) / var(--background-alpha, 100%)
        );
        border-color: rgb(var(--border-color, var(--default-1)) / var(--border-alpha, 100%));
        color: rgb(var(--color, var(--default-2)) / 100%);
    }

    svg {
        flex: 0 0 auto;
        height: 1.712em;
        width: auto;
    }
}

.primary {
    --background-alpha: 12%;
    --background-color: #{$color-focus-rgb};
    --border-alpha: 100%;
    --border-color: #{$color-focus-rgb};
    --color: #{$color-focus-rgb};

    &:not(span):hover {
        --background-alpha: 100%;
        --background-color: #{$color-focus-rgb};
        --border-alpha: 100%;
        --border-color: #{$color-focus-rgb};
        --color: #{$color-base-rgb};
    }
}

.secondary {
    --background-alpha: 12%;
    --background-color: #{$color-focus-rgb};
    --border-alpha: 0%;
    --border-color: 0 0 0;
    --color: #{$color-focus-rgb};

    &:not(span):hover {
        --background-alpha: 100%;
        --background-color: #{$color-focus-rgb};
        --border-alpha: 0%;
        --border-color: 0 0 0;
        --color: #{$color-base-rgb};
    }
}

.tertiary {
    --background-alpha: 8%;
    --background-color: #{$color-main-rgb};
    --border-alpha: 0%;
    --border-color: 0 0 0;
    --color: #{$color-main-rgb};

    &:not(span):hover {
        --background-alpha: 100%;
        --background-color: #{$color-main-rgb};
        --border-alpha: 0%;
        --border-color: 0 0 0;
        --color: #{$color-focus-rgb};
    }
}

.inline {
    --background-alpha: 0%;
    --background-color: #{$color-main-rgb};
    --border-alpha: 0%;
    --border-color: 0 0 0;
    --color: #{$color-main-rgb};

    font-size: 1em;
    line-height: 1;
    min-width: 0;
    padding: 0.5em;

    svg {
        height: 1.5em;
    }

    &:not(span):hover {
        --background-alpha: 100%;
        --background-color: #{$color-main-rgb};
        --border-alpha: 0%;
        --border-color: 0 0 0;
        --color: #{$color-focus-rgb};
    }
}
