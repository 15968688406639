@use '@/styles/utils' as *;

.globalFooter {
    --margin-adjustment: 15rem;
    --buffer: 120px;
    --footer-spacing: 2.4rem;

    margin-block-start: (calc(var(--buffer) * -1));
    padding: calc(var(--margin-adjustment) / 2) 3.2rem 3.2rem 3.2rem;
    position: relative;
    z-index: 5;
}

.logos {
    column-gap: 3.2rem;
    display: flex;
    justify-content: center;
    padding-block-end: var(--footer-spacing);

    @media (width <= 500px) {
        flex-direction: column;
        gap: 1.5rem;

        .projectLogo {
            border-right: none;
            justify-content: center;
            padding-inline-end: 0;
        }
    }
}

.projectLogo {
    align-items: center;
    border-right: 1px solid #c3c3c3;
    display: inline-flex;
    padding-inline-end: 3.2rem;
}

.gameLogo {
    aspect-ratio: 200 / 56;
    height: auto;
    max-width: 182px;
    width: 100%;
}

.ratings {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.rating {
    height: 100%;
    list-style: none;
    width: 100%;

    img {
        aspect-ratio: 68 / 67;
        display: block;
        height: 100%;
        max-height: 5rem;
        max-width: 6rem;
        width: auto;
    }

    &.rating-icon-ESRB {
        img {
            aspect-ratio: 2.005 / 0.962;
            max-height: 100%;
            max-width: 18rem;
        }
    }
}

.copyright {
    margin-inline: auto;
    max-width: 656px;
    padding-block-end: var(--footer-spacing);
    text-align: center;

    p {
        @include caption;
    }
}

.auxilaryLogos {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-block-start: var(--footer-spacing);
}

.studioLogo,
.kraftonLogo {
    fill: $color-main;
    height: auto;
    margin-inline: 1rem;
    max-width: 150px;
    width: 100%;
}

.studioLogo {
    aspect-ratio: 100/33;
    color: $color-main;
}

.kraftonLogo {
    aspect-ratio: 100/16;
}

.legalLinks {
    line-height: 1;

    ul {
        align-items: center;
        column-gap: 2.4rem;
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0 1rem;

        @media (width <= 900px) {
            column-gap: 1.2rem;
        }
    }
}

.footerLinkLi,
.footerLink {
    @include break-word;

    cursor: pointer;

    &.bold {
        font-weight: bold;
    }
}

.backgroundImage {
    clip-path: polygon(0 calc(var(--buffer) - 10px), 100% 10px, 100% 100%, 0 100%);
    object-fit: cover;
    z-index: -1;
}

.tearEffect {
    fill: #000;
    height: var(--buffer);
    left: 0;
    position: absolute;
    scale: -1 1;
    top: 0;
    translate: 0 0%;
    width: 100%;
}

.socials {
    align-items: center;
    display: flex;
    gap: 2rem;
    justify-content: center;
    list-style: none;
    margin: 0;
    margin-top: calc(var(--margin-adjustment) / 2);
    padding: 0;
    padding-block-end: var(--footer-spacing);

    @media (width <= 500px) {
        gap: 1.5rem;
    }

    img,
    svg {
        height: 4rem;
        width: 4rem;
    }
}
