@use '@/styles/utils' as *;

.container {
    position: relative;
    white-space: nowrap;
}

.label {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 0.5rem;
    padding-inline: 0 1.85em;
    position: relative;
}

.text {
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    position: relative;
    text-transform: uppercase;

    .label.open &::after {
        rotate: 180deg;
    }
}

.arrow {
    display: block;
    height: auto;
    inset-block-start: 0;
    inset-inline-end: 0;
    position: absolute;
    width: 1.5em;
}

.list {
    --scrollbar: #{$color-main-rgb};

    background-color: $color-base;
    max-height: var(--max-height, calc(100vh - 4rem));
    overflow: auto;
    position: absolute;
    right: 0;
    scrollbar-color: rgb(var(--scrollbar) / 20%) transparent;
    scrollbar-width: thin;
    top: calc(100% + 1rem);
    visibility: hidden;

    &::-webkit-scrollbar {
        background-color: transparent;
        height: 0.6rem;
        width: 0.6rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(var(--scrollbar) / 20%);
        border-radius: 10px;
    }

    &.open {
        visibility: visible;
    }
}

.item {
    --color: #{$color-base-rgb};

    background-color: rgb(var(--color) / 20%);
    border-left: 2px solid transparent;
    cursor: pointer;
    display: block;
    font-size: 1.6rem;
    padding: 1rem 1.5rem;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            --color: #{$color-focus-rgb};

            background-color: rgb(255 78 78 / 30%);
            border-color: $color-accent-red;
        }
    }
}
