@use '@/styles/utils' as *;

.marquee {
    background-color: rgb(255 255 255 / 45%);
    left: 0;
    padding-block: 3rem 1.5rem;
    position: relative;
    top: 0;
    z-index: 2;

    @media (width <= $container-width-min) {
        padding-block: 2.4rem 0.8rem;
    }

    .stat {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 1.6rem;
        justify-content: center;
        padding-inline: 1.2rem;
        text-transform: uppercase;

        .statTitle,
        .statData {
            font: 400 2.4rem/3.778rem $fonts-saira;
            letter-spacing: 0.25px;

            @media (width <= $container-width-min) {
                font-size: 1.4rem;
                line-height: 2.204rem;
            }
        }

        .statData {
            color: rgb(255 81 78 / 100%);
        }

        .statTitle {
            color: $color-base;
        }
    }
}
