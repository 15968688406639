@use '@/styles/utils' as *;

.desktop {
    aspect-ratio: 16 / 9;
    display: block;
    height: auto;

    @media (width <= $container-width-min) {
        display: none;
    }
}

.mobile {
    aspect-ratio: 9 / 16;
    display: none;
    height: auto;

    @media (width <= $container-width-min) {
        display: block;
    }
}
