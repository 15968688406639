@use '@/styles/utils.scss' as *;

.footer,
.group,
.set {
    --color: #{$color-dark-grey-rgb};

    border: unset;
    display: grid;
    gap: 1.5em;
    grid-template: auto / 1fr 1fr;

    &:not(:last-child) {
        margin-bottom: 2.4rem;
    }
}

.footer {
    border-top: 1px solid $color-dark-grey;
    grid-column: 1/ -1;
    padding-top: 1.5em;
}

.group {
    background-color: rgb(var(--color) / 20%);
    border: 1px solid rgb(var(--color) / 100%);
    border-radius: 0.67em;
    padding: 1em;
}

.span {
    grid-column: 1 / -1;
}

.field {
    display: block;
    position: relative;
    z-index: 0;

    p {
        line-height: 1.333;
        margin-block-start: 0.333em;
        margin-inline: 1em;
    }

    &:has(.error) {
        --color: $color-error-rgb;

        color: $color-error;
    }
}

.error {
    align-items: center;
    color: $color-error;
    display: inline-flex;
    gap: 0.8rem;
    margin-block-start: 0.8rem;
}

.errorCircle {
    width: 1.6rem;
}

.note {
    color: $color-med-grey;
}

.input {
    background-color: $color-gray-300;
    color: $color-gray-1000;

    &[type='email'] {
        padding-block: 2.4rem 0.8rem;
        width: 100%;
    }

    &::selection {
        background-color: $color-main;
    }

    &:focus-visible {
        color: $color-gray-1000;
        outline: dashed 1px currentcolor;
        outline-offset: 0.2em;
    }
}

.textarea {
    resize: none;
}

.add {
    display: flex;
    gap: 0.5rem;

    &:disabled * {
        pointer-events: none;
    }
}

.addButton {
    aspect-ratio: 1;
    padding: 0;
    width: calc(3.712em);
}
