@use '@/styles/utils' as *;

.mobileNavigation {
    display: flex;
    flex-direction: column;
    height: 100%;

    a {
        text-align: center;
    }

    a.logoLink {
        text-align: left;
    }
}

.innerWrapper {
    border: 1px solid $color-accent-green;
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-block: 4rem 3rem;
    overflow-y: scroll;
    padding: 2rem 3rem;
    row-gap: 2rem;
}

.languageList {
    height: 0;
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    top: unset;
    transition: height 0.3s ease-out;
}

.languageLabel {
    background-color: rgba($color-main, 0.1);
    padding: 2rem;
    width: 100%;

    &[aria-expanded='true'] {
        ~ .languageList {
            height: auto;
        }
    }

    span {
        margin-inline: auto;
    }
}

.languageArrow {
    position: relative;
}

.subscribeText,
.login {
    color: $color-seafoam-green;
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.login {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-family: $fonts-saira;
    gap: 0.5rem;
    justify-content: center;
    pointer-events: all;

    &:hover {
        opacity: 0.8;
    }
}

.submit {
    border-image: url('/assets/images/tape-effect--blue.svg');
    border-image-outset: 8px 8px 8px 8px;
    border-image-repeat: stretch stretch;
    border-image-slice: 0 0 0 0 fill;
    border-image-width: 10px 10px 10px 10px;
    color: $color-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.25px;
    line-height: normal;
    padding: 2rem 5rem;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    z-index: 2;
}

.gameLogo {
    height: 56px;
    max-width: 100%;
    width: 200px;
}
